<template>
  <div class="wap-auth" :class="pageType == 'user' ? 'user' : ''">
    <headerBar :title="$t('xiu-gai-deng-lu-mi-ma')" @back="back"></headerBar>

    <div class="main wap-page-form">
      <div class="form-item">
        <div class="label">{{ $t('jiu-mi-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.OldPassword"
            :type="showPassword ? 'text' : 'password'"
            :placeholder="$t('qing-shu-ru-jiu-mi-ma-4')"
          >
            <template #button>
              <div class="right-icon" @click="showPassword = !showPassword">
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('xin-mi-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.Password"
            :type="showPassword2 ? 'text' : 'password'"
            :placeholder="$t('qing-shu-ru-xin-mi-ma-4')"
          >
            <template #button>
              <div class="right-icon" @click="showPassword2 = !showPassword2">
                <img :src="eyeUrl" alt="" v-if="!showPassword2" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('que-ren-mi-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.PasswordConfirm"
            :type="showPassword3 ? 'text' : 'password'"
            :placeholder="$t('qing-que-ren-mi-ma-2')"
          >
            <template #button>
              <div class="right-icon" @click="showPassword3 = !showPassword3">
                <img :src="eyeUrl" alt="" v-if="!showPassword3" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </Field>
        </div>
      </div>

      <div class="form-btn">
        <Button class="submit-btn" @click="submit">{{ $t('xiu-gai') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import headerBar from '@/components/header'
import { editPassword } from '@/api/user'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      form: {
        OldPassword: '',
        Password: '',
        PasswordConfirm: ''
      },
      pageType: '',
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png')
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    let name = this.$route.name
    this.pageType = name === 'wapUserEditPassword' ? 'user' : ''
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    save() {
      this.$store.state.avatar = this.avatar
      this.back()
    },
    changeHeader(i) {
      this.avatar = i
    },
    changeCountry() {
      this.showDialog = true
    },
    submit() {
      if (!this.form.OldPassword) {
        this.$toast(this.$t('qing-shu-ru-jiu-mi-ma-0'))
        return
      }
      if (!this.form.Password) {
        this.$toast(this.$t('qing-shu-ru-xin-mi-ma-0'))
        return
      }
      if (!this.form.PasswordConfirm) {
        this.$toast(this.$t('qing-que-ren-mi-ma-0'))
        return
      }
      if (this.form.Password != this.form.PasswordConfirm) {
        this.$toast(this.$t('liang-ci-shu-ru-de-mi-ma-bu-yi-zhi'))
        return
      }
      editPassword({
        OldPassword: this.form.OldPassword,
        Password: this.form.Password,
        PasswordConfirm: this.form.PasswordConfirm
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('xiu-gai-cheng-gong'))
          setTimeout(() => {
            this.back()
          }, 1000)
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>